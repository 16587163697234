import { EnvironmentProviders, Provider } from '@angular/core';
import { abiosConfigName } from '@bcf-v2-configurators/tokens/abios/lvbet/abios-config-name';
import { ABIOS_CONFIG_NAME } from '@bcf-v2-configurators/tokens/abios/token';
import { appMaintenanceUrl } from '@bcf-v2-configurators/tokens/app-maintenance/lvbetpl/app-maintenance-url';
import { APP_MAINTENANCE_URL } from '@bcf-v2-configurators/tokens/app-maintenance/token';
import { QUICK_BLIK_IN_BETSLIP_ENABLED } from '@bcf-v2-configurators/tokens/betslip/token';
import { lotteryAssetsUrlValue } from '@bcf-v2-configurators/tokens/cookie-consent/projects/sportsbook/lvbetpl/lottery-assets-url';
import { dateFnsI18n } from '@bcf-v2-configurators/tokens/date-fns-i18n/lvbetpl/date-fns-i18n';
import { DATE_FNS_I18N } from '@bcf-v2-configurators/tokens/date-fns-i18n/token';
import { myBetsListDateFormat } from '@bcf-v2-configurators/tokens/date-format/letsbetmd/my-bets-list-date-format';
import { resultCenterDateFormat } from '@bcf-v2-configurators/tokens/date-format/lvbetpl/results-center-date-format';
import { MY_BETS_LIST_DATE_FORMAT, RESULT_CENTER_DATE_FORMAT } from '@bcf-v2-configurators/tokens/date-format/token';
import { defaultLang } from '@bcf-v2-configurators/tokens/default-lang/lvbetpl/default-lang';
import { DEFAULT_LANG } from '@bcf-v2-configurators/tokens/default-lang/token';
import { I18N_OPTIMOVE_DESC_LOADER } from '@bcf-v2-configurators/tokens/descs-optimove-i18n/token';
import { I18N_DESC_LOADER } from '@bcf-v2-configurators/tokens/descs/token';
import {
  editProfileAddressAvailableFieldsFn,
  editProfilePrivacyAndNotificationsAvailableFields
} from '@bcf-v2-configurators/tokens/edit-profile/lvbetcom/edit-profile-available-fields';
import { editProfilePrivacyAndNotificationsShowContactSupportInfo } from '@bcf-v2-configurators/tokens/edit-profile/lvbetpl/edit-profile-privacy-and-notifications-show-contact-support-info';
import { editProfileShowContactSupportInfo } from '@bcf-v2-configurators/tokens/edit-profile/lvbetpl/edit-profile-show-contact-support-info';
import {
  EDIT_PROFILE_ADDRESS_AVAILABLE_FIELDS,
  EDIT_PROFILE_PRIVACY_AND_NOTIFICATIONS_AVAILABLE_FIELDS,
  EDIT_PROFILE_PRIVACY_AND_NOTIFICATIONS_SHOW_CONTACT_SUPPORT_INFO,
  EDIT_PROFILE_SHOW_CONTACT_SUPPORT_INFO
} from '@bcf-v2-configurators/tokens/edit-profile/token';
import { footerBrandName } from '@bcf-v2-configurators/tokens/footer/lvbetpl/footer-brand-name';
import { footerLinksFn } from '@bcf-v2-configurators/tokens/footer/lvbetpl/footer-links';
import { FOOTER_BRAND_NAME, FOOTER_LINKS } from '@bcf-v2-configurators/tokens/footer/token';
import { GOOGLE_LOCALES_MAP_CONFIG_LOADER } from '@bcf-v2-configurators/tokens/google-locales-map/token';
import { ladeskChatConfig } from '@bcf-v2-configurators/tokens/ladesk-chat/lvbetpl/ladesk-chat-config';
import { LADESK_CHAT_CONFIG } from '@bcf-v2-configurators/tokens/ladesk-chat/token';
import { lvWheelConfig } from '@bcf-v2-configurators/tokens/lv-wheel/lvbetpl/lv-wheel-config';
import { LV_WHEEL_CONFIG } from '@bcf-v2-configurators/tokens/lv-wheel/token';
import { lvisionBetBoosterApiKeys } from '@bcf-v2-configurators/tokens/lvision-bet-booster/lvbetpl/lvision-bet-booster-api-keys';
import { LVISION_BET_BOOSTER_API_KEYS } from '@bcf-v2-configurators/tokens/lvision-bet-booster/token';
import { mobileApplicationAppConfig } from '@bcf-v2-configurators/tokens/mobile-application/sportsbook/lvbetpl/config';
import { MOBILE_APPLICATION_APPS_CONFIG } from '@bcf-v2-configurators/tokens/mobile-application/token';
import { commonTaxPayoutDetailsInfo } from '@bcf-v2-configurators/tokens/my-bets/lvbetpl/common-tax-payout-details-info';
import { customTaxPayoutDetailsInfo } from '@bcf-v2-configurators/tokens/my-bets/lvbetpl/custom-tax-payout-details-info';
import {
  COMMON_TAX_PAYOUT_DETAIL_INFO,
  CUSTOM_TAX_PAYOUT_DETAIL_INFO
} from '@bcf-v2-configurators/tokens/my-bets/token';
import { OPTIMOVE_SDK_CONFIG_LOADER } from '@bcf-v2-configurators/tokens/optimove-sdk/tokens';
import { PUSH_NOTIFICATIONS_CONFIG_LOADER } from '@bcf-v2-configurators/tokens/push-notifications/token';
import { rgTakeBreakCustomSelfExclusionMessageFn } from '@bcf-v2-configurators/tokens/rg-take-break/lvbetpl/rg-take-break-custom-self-exclusion-message';
import { RG_TAKE_BREAK_CUSTOM_SELF_EXCLUSION_MESSAGE_FN } from '@bcf-v2-configurators/tokens/rg-take-break/token';
import { statscoreVizuConfigMobile } from '@bcf-v2-configurators/tokens/statscore-vizu-config/lvbet/statscore-vizu-config';
import { STATSCORE_VIZU_CONFIG } from '@bcf-v2-configurators/tokens/statscore-vizu-config/token';
import { bonusCustomName } from '@bcf-v2-configurators/tokens/user-bonuses/lvbetpl/bonus-custom-name';
import { enabledBonusCancel } from '@bcf-v2-configurators/tokens/user-bonuses/lvbetpl/enabled-bonus-cancel';
import { BONUS_CUSTOM_NAME, ENABLED_BONUS_CANCEL } from '@bcf-v2-configurators/tokens/user-bonuses/token';
import { wizardPostFormLayoutConfig } from '@bcf-v2-configurators/tokens/wizard-post/curacao/wizard-post-form-layout-config';
import { WIZARD_POST_FORM_LAYOUT_CONFIG } from '@bcf-v2-configurators/tokens/wizard-post/token';
import { LOTTERY_ASSETS_URL } from '@bcf-v2-ui-lottery/logic/lottery-wheel/img-loader/assets-url.token';

export const settingsProviders: (Provider | EnvironmentProviders)[] = [
  {
    provide: DEFAULT_LANG,
    useValue: defaultLang
  },
  {
    provide: APP_MAINTENANCE_URL,
    useValue: appMaintenanceUrl
  },
  {
    provide: LV_WHEEL_CONFIG,
    useValue: lvWheelConfig
  },
  {
    provide: I18N_DESC_LOADER,
    useValue: () =>
      import(
        /* webpackChunkName: "const-i18n-desc" */ '@bcf-v2-configurators/tokens/descs/lvbetpl/mobile-web/i18n-descs'
        // eslint-disable-next-line @typescript-eslint/typedef
      ).then((mod) => mod.i18nDesc)
  },
  {
    provide: EDIT_PROFILE_PRIVACY_AND_NOTIFICATIONS_AVAILABLE_FIELDS,
    useValue: editProfilePrivacyAndNotificationsAvailableFields
  },
  {
    provide: EDIT_PROFILE_ADDRESS_AVAILABLE_FIELDS,
    useValue: editProfileAddressAvailableFieldsFn
  },
  {
    provide: EDIT_PROFILE_PRIVACY_AND_NOTIFICATIONS_SHOW_CONTACT_SUPPORT_INFO,
    useValue: editProfilePrivacyAndNotificationsShowContactSupportInfo
  },
  {
    provide: EDIT_PROFILE_SHOW_CONTACT_SUPPORT_INFO,
    useValue: editProfileShowContactSupportInfo
  },
  {
    provide: RG_TAKE_BREAK_CUSTOM_SELF_EXCLUSION_MESSAGE_FN,
    useValue: rgTakeBreakCustomSelfExclusionMessageFn
  },
  {
    provide: LADESK_CHAT_CONFIG,
    useValue: ladeskChatConfig
  },
  {
    provide: MOBILE_APPLICATION_APPS_CONFIG,
    useValue: mobileApplicationAppConfig
  },
  {
    provide: STATSCORE_VIZU_CONFIG,
    useValue: statscoreVizuConfigMobile
  },
  {
    provide: ABIOS_CONFIG_NAME,
    useValue: abiosConfigName
  },
  {
    provide: WIZARD_POST_FORM_LAYOUT_CONFIG,
    useValue: wizardPostFormLayoutConfig
  },
  {
    provide: ENABLED_BONUS_CANCEL,
    useValue: enabledBonusCancel
  },
  {
    provide: BONUS_CUSTOM_NAME,
    useValue: bonusCustomName
  },
  {
    provide: FOOTER_LINKS,
    useValue: footerLinksFn
  },
  {
    provide: FOOTER_BRAND_NAME,
    useValue: footerBrandName
  },
  {
    provide: MY_BETS_LIST_DATE_FORMAT,
    useValue: myBetsListDateFormat
  },
  {
    provide: PUSH_NOTIFICATIONS_CONFIG_LOADER,
    useValue: () =>
      import('@bcf-v2-configurators/tokens/push-notifications/lvbetpl/push-notifications-config').then(
        // eslint-disable-next-line @typescript-eslint/typedef
        (mod) => mod.pushNotificationsConfigs
      )
  },
  {
    provide: DATE_FNS_I18N,
    useValue: dateFnsI18n
  },
  {
    provide: GOOGLE_LOCALES_MAP_CONFIG_LOADER,
    useValue: () =>
      import('@bcf-v2-configurators/tokens/google-locales-map/lvbetpl/google-locales-map-config').then(
        // eslint-disable-next-line @typescript-eslint/typedef
        (mod) => mod.googleMapsLocalesConfig
      )
  },
  {
    provide: OPTIMOVE_SDK_CONFIG_LOADER,
    useValue: () =>
      import('@bcf-v2-configurators/tokens/optimove-sdk/lvbetpl/mobile-web/optimove-sdk-config').then(
        // eslint-disable-next-line @typescript-eslint/typedef
        (mod) => mod.optimoveSdkConfigs
      )
  },
  {
    provide: I18N_OPTIMOVE_DESC_LOADER,
    useValue: () =>
      import('@bcf-v2-configurators/tokens/descs-optimove-i18n/lvbetpl/sportsbook/i18n-optimove-desc').then(
        // eslint-disable-next-line @typescript-eslint/typedef
        (mod) => mod.i18nOptimoveDesc
      )
  },
  {
    provide: QUICK_BLIK_IN_BETSLIP_ENABLED,
    useValue: true
  },
  {
    provide: LVISION_BET_BOOSTER_API_KEYS,
    useValue: lvisionBetBoosterApiKeys
  },
  {
    provide: COMMON_TAX_PAYOUT_DETAIL_INFO,
    useValue: commonTaxPayoutDetailsInfo
  },
  {
    provide: CUSTOM_TAX_PAYOUT_DETAIL_INFO,
    useValue: customTaxPayoutDetailsInfo
  },
  {
    provide: RESULT_CENTER_DATE_FORMAT,
    useValue: resultCenterDateFormat
  },
  {
    provide: LOTTERY_ASSETS_URL,
    useValue: lotteryAssetsUrlValue
  }
];
